import React, {useState} from "react";
import clsx from 'clsx';
import { Link, useStaticQuery, graphql } from "gatsby";
import YAMLData from "../../static/content/navbar.yml";

const topLinks = [
  {
    url: "/",
    label: "Home"
  },
  {
    url: "/services",
    label: "Services"
  },
  {
    url: "/price-list",
    label: "Price List"
  },
  {
    url: "/products",
    label: "Products"
  },
  {
    url: "/contact",
    label: "Contact"
  }
];

const topSocials = [
  {
    name: "facebook",
    url: "https://www.facebook.com/FairBeautyMiranda",
    icon: "fab fa-facebook"
  },
  {
    name: "instagram",
    url: "https://www.instagram.com/fairbeauty_/",
    icon: "fab fa-instagram"
  },
  {
    name: "email",
    url: "mailto:christineroby@hotmail.com",
    icon: "fas fa-envelope"
  }
];

function Navbar() {
  const [isOpen, setIsOpen] = useState(undefined);

  const { site } = useStaticQuery (
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            header
          }
        }
      }
    `
  );

  function handleBurgerClick(e) {
    e.preventDefault();
    setIsOpen(!isOpen);
  }

  const {
    logo = false
  } = YAMLData;

  return (
    <>
      <nav className="top top--alert">
        <div className="container">
          <div className="top__links has-mobile-text-center">
            <p className="has-no-margin">
              {YAMLData.announcement}
            </p>
          </div>
          <div className="top__social is-end is-tablet-up">
            <ul>
              {topSocials.map(social => (
                <li key={social.name}>
                  <a href={social.url}>
                    <i className={social.icon + ' fa-lg'}
                      aria-label={social.name + 'icon link'}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>

      <nav className={clsx('top top--navbar is-mobile-responsive', {
        'is-open': isOpen
      })}>
        <div className="container">
          <div className="top__brand">
            <a href={site.siteMetadata.siteUrl}>
              {logo
                ? <img alt="Fair Beauty logo"
                  src={logo} />
                : <h4>{site.siteMetadata.header}</h4>
              }
            </a>

          </div>
          <div className="top__links is-end">
            <ul>
              {topLinks.map(link => (
                <li key={link.url}>
                  <Link to={link.url}
                    activeClassName="is-active">{link.label}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="top__social is-end is-mobile-only">
            <ul>
              {topSocials.map(social => (
                <li key={social.name}>
                  <a href={social.url}>
                    <i className={social.icon + ' fa-lg'}
                      aria-label={social.name + 'icon link'}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <a className="top__burger is-end has-text-black"
            href="/#"
            onClick={handleBurgerClick}>
            <span></span>
          </a>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
export {
  topLinks
};
