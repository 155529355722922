import React from 'react';
import YAMLData from "../../static/content/footer.yml";

function Footer() {
  const {
    about,
    storeOne,
    storeTwo
  } = YAMLData;
  return (
    <section className="section section--footer has-link-inherit">
      <div className="container grid has-row-margin">
        <div className="column is-half">
          <h6>{about.heading}</h6>
          <ul>
            <li className="has-white-space">{about.text}</li>
          </ul>
        </div>
        <div className="column is-half">
          <h6>{storeOne.heading}</h6>
          <ul>
            <li>Tel: <a href={"tel:"+storeOne.phone}>{storeOne.phone}</a></li>
            <li className="has-white-space">{storeOne.address}</li>
          </ul>
        </div>
        <div className="column is-full">
          &copy; {new Date().getFullYear()} - All Rights Reserved - Fair Beauty
        </div>
      </div>
    </section>
  )
}

export default Footer;
