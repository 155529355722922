import React from "react";
import { Helmet } from "react-helmet";
import "../style/orbit.min.css";
import "../style/layout.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { motion } from "framer-motion";

export default function Layout({
  children,
  homePage
}) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="shortcut icon" href="/assets/favi.png" />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.5.0/css/all.css" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@300&family=Montserrat&display=swap" rel="stylesheet" />
      </Helmet>
      <Navbar />
      <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0}}
          transition={{
            type: "spring",
            mass: 0.35,
            stiffness: 75,
            duration: 0.2
          }}
        >
        {children}
      </motion.main>
      <Footer />
    </>
  );
}
